import { Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, IconButton, useTheme } from "@mui/material";
import CloseIcon from '@mui/icons-material/Close';
import * as colors from './colors';
import { useContext, useRef } from "react";
import { AasTokenContext } from "./providers/AasTokenContext";
import { CoreModeContext } from "./providers/CoreModeContext";

type LogoutModalProps = {
    open: boolean;
    onClose: () => void;
}

export function LogoutModal({open, onClose}: LogoutModalProps) {
    const { endpoint } = useContext(AasTokenContext);
    const { tokensStore } = useContext(CoreModeContext);

    const formRef = useRef<HTMLFormElement>(null);
    const theme = useTheme();

    function handleLogoutClick() {
        onClose();

        tokensStore.clearTokens();

        formRef.current!.submit();
    }

    const logoutURL = `${endpoint}/account/logout?ReturnUrl=${window.location.href}`;

    return (
        <Dialog open={open}>
            <DialogTitle sx={{ backgroundColor: theme.palette.background.default, color: theme.palette.text.primary }}>
                Logging out
            </DialogTitle>

            <IconButton aria-label="Close" onClick={onClose} sx={{ position: 'absolute', right: 0, top: 0, color: theme.palette.primary.main }}>
                <CloseIcon></CloseIcon>
            </IconButton>

            <DialogContent sx={{ backgroundColor: colors.lightGreyBlue }}>
                <DialogContentText sx={{ color: theme.palette.text.primary }}>
                    Are you sure you want to log out?
                </DialogContentText>
            </DialogContent>

            <DialogActions>
                <Button variant="outlined" onClick={onClose}>Cancel</Button>
                <form ref={formRef} method="post" action={logoutURL}>
                    <Button variant="contained" onClick={handleLogoutClick} type="submit" autoFocus>Log Out</Button>
                </form>
            </DialogActions>
        </Dialog >
    )
}
