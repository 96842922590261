import { useContext, useEffect, useState } from 'react';
import { AasTokenContext } from './providers/AasTokenContext';
import { CoreModeContext } from './providers/CoreModeContext';

export function useAas<T>(pathAndQuery: string): T | null {
  const [data, setData] = useState(null as T | null);
  const { endpoint, apiToken } = useContext(AasTokenContext);
  const { tokensStore } = useContext(CoreModeContext);

  useEffect(() => {
    let ignore = false;

    (async function () {
      const response = await fetch(`${endpoint}${pathAndQuery}`, {
        headers: {
          Accept: 'application/json',
          Authorization: 'Bearer ' + apiToken
        }
      });

      if (response.status === 401) {
        tokensStore.handle401();

        return;
      }

      const data = await response.json() as T;

      if (!ignore) {
        setData(data);
      }
    })();

    return () => {
      ignore = true;
    };
  }, [pathAndQuery, apiToken, tokensStore, endpoint]);

  return data;
}