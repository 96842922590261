import { useContext } from "react";
import { Dialog, DialogActions, DialogTitle, Grid, IconButton, useTheme } from "@mui/material";
import { Module } from "./classic-store";
import { ApplicationMenuItem } from "./ApplicationMenuItem";
import { ModuleEndpointContext } from "../providers/ModuleEndpointContext";
import { ModulesVisibleInCoreContext } from "../providers/ModulesVisibleInCoreContext";
import CloseIcon from '@mui/icons-material/Close';
import * as colors from '../colors';

type Props = {
    open: boolean;
    onClose: () => void;
}

export function ModuleSelectModal({ open, onClose }: Props) {
    const theme = useTheme();
    const modulesVisibleInCore = useContext(ModulesVisibleInCoreContext);
    const { loadApplication } = useContext(ModuleEndpointContext);

    const handleClick = (application: Module) => {
        onClose();

        loadApplication(application.endpoint, application.code);
    };

    return (
        <Dialog onClose={onClose} open={open} maxWidth="lg">
            <DialogTitle sx={{ backgroundColor: theme.palette.background.default, color: theme.palette.text.primary }}>
                Your Apps
            </DialogTitle>

            <IconButton aria-label="Close" onClick={onClose} sx={{ position: 'absolute', right: 0, top: 0 }}>
                <CloseIcon></CloseIcon>
            </IconButton>

            <DialogActions sx={{ background: colors.lightGreyBlue, padding: 2 }}>
                <Grid container spacing={2}>
                    {
                        modulesVisibleInCore.map(x =>
                            <Grid item>
                                <ApplicationMenuItem key={x.code} application={x} onClick={() => handleClick(x)}>
                                </ApplicationMenuItem>
                            </Grid>
                        )
                    }
                </Grid>
            </DialogActions>
        </Dialog>
    )
}
