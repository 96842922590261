import { useContext, useEffect, useState } from 'react';
import { ModuleSelectModal } from './ModuleSelectModal.js';
import { UserInformationContext } from '../providers/UserInformationContext.js';
import { ModuleEndpointContext } from '../providers/ModuleEndpointContext.js';
import { SettingsContext } from '../providers/SettingsContext.js';
import { ModulesVisibleInCoreContext } from '../providers/ModulesVisibleInCoreContext.js';

export function ResolveAppToShow() {
  const [open, setOpen] = useState(false);
  const settings = useContext(SettingsContext);
  const modulesVisibleInCore = useContext(ModulesVisibleInCoreContext);
  const context = useContext(UserInformationContext);
  const { selectedModule } = context;
  const { loadApplication } = useContext(ModuleEndpointContext);

  useEffect(() => {
    const queryStringParameters = new URLSearchParams(window.location.search);

    const moduleCode = queryStringParameters.get("module");

    // if there is something in the URL - use that
    if (moduleCode) {
      const moduleToLoad = modulesVisibleInCore.find(m => m.code.toUpperCase() === moduleCode.toUpperCase());
      if (moduleToLoad) {
        const path = queryStringParameters.get("path");
        const query = queryStringParameters.get("query");

        loadApplication(`${moduleToLoad.endpoint}${path}${query}`, moduleToLoad.code);

        return;
      }
    }

    const moduleToOpenImmediately = [
      selectedModule,                           // if user previously opened a module - use that
      settings.DefaultModule,                   // if there is a suggestion from AAS - use that
      modulesVisibleInCore.length === 1       // if there is only one module anyway - use that
        ? modulesVisibleInCore[0].code
        : null
    ]
      .filter(x => !!x)
      .map(code => modulesVisibleInCore.find(module => module.code.toUpperCase() === code!.toUpperCase()))
      .filter(x => !!x)[0];

    if (moduleToOpenImmediately) {
        loadApplication(moduleToOpenImmediately.endpoint, moduleToOpenImmediately.code);

        return;
    }

    // open menu
    setOpen(true);
  },
    []); // run once

  return (<ModuleSelectModal
    open={open}
    onClose={() => setOpen(false)}
  />);
}
