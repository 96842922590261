// animated logo

export function LoadingLogo() {
    return (<>
        
        <div className="implico-core-ui-loader">
            <div className="implico-core-ui-logo">
                <svg viewBox="0 0 128 38" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M0 7.51758H4.51876V11.4597H0V7.51758ZM0.0947416 32.138V14.9239H4.42402V32.138H0.0947416Z" fill="#FFFFFF" />
                    <path d="M12.337 17.2308C13.4286 15.5337 15.0928 14.5698 17.3707 14.5698C19.6486 14.5698 21.5393 15.5955 22.4373 17.3256C23.6566 15.4678 25.5473 14.5698 27.8541 14.5698C31.3801 14.5698 33.7198 16.7818 33.7198 20.1143V32.1341H29.3905V20.8187C29.3905 19.2163 28.4596 17.964 26.5071 17.964C24.3898 17.964 23.0758 19.4387 23.0758 21.4283V32.1341H18.7465V20.8187C18.7465 19.2163 17.7867 17.964 15.8919 17.964C13.7746 17.964 12.4277 19.4387 12.4277 21.4283V32.1341H8.09839V14.92H12.1682L12.2629 17.2267H12.337V17.2308Z" fill="#FFFFFF" />
                    <path d="M41.7605 29.9221H41.6987V37.9998H37.3694V14.92H41.4392L41.5339 17.4862H41.5998C42.9138 15.6573 44.9034 14.5698 47.1772 14.5698C51.8237 14.5698 54.5835 18.4172 54.5835 23.4508C54.5835 28.4845 51.7001 32.4883 47.0495 32.4883C44.8045 32.4883 43.0415 31.5574 41.7605 29.9221ZM50.1925 23.5126C50.1925 20.5962 48.8784 17.9682 45.8632 17.9682C43.0086 17.9682 41.5669 20.4685 41.5669 23.6115C41.5669 26.7544 43.0745 29.0941 45.8302 29.0941C48.7466 29.0941 50.1883 26.6267 50.1883 23.5167H50.1925V23.5126Z" fill="#FFFFFF" />
                    <path d="M57.1458 32.1339V7.51758H61.475V32.138H57.1458V32.1339Z" fill="#FFFFFF" />
                    <path d="M65.0586 7.51758H69.5774V11.4597H65.0586V7.51758ZM65.1533 32.138V14.9239H69.4826V32.138H65.1533Z" fill="#FFFFFF" />
                    <path d="M72.012 23.6073C72.012 18.4789 75.089 14.5698 80.6994 14.5698C84.2872 14.5698 86.6928 16.2051 88.0068 18.7673L84.6085 20.5962C83.8382 18.9609 82.6519 17.9023 80.7611 17.9023C77.8777 17.9023 76.403 20.4026 76.403 23.5456C76.403 26.6885 77.8447 29.1559 80.893 29.1559C82.8496 29.1559 84.0977 28.0973 84.868 26.4949L88.1386 28.2909C86.8864 30.7912 84.386 32.4925 80.7035 32.4925C75.159 32.4925 72.0161 28.711 72.0161 23.6115L72.012 23.6073Z" fill="#FFFFFF" />
                    <path d="M88.8389 23.5456C88.8389 18.4172 91.9818 14.5698 97.6869 14.5698C103.392 14.5698 106.535 18.4172 106.535 23.5456C106.535 28.674 103.392 32.4883 97.6869 32.4883C91.9818 32.4883 88.8389 28.641 88.8389 23.5456ZM93.2299 23.5456C93.2299 26.7215 94.7046 29.1559 97.6869 29.1559C100.669 29.1559 102.144 26.7173 102.144 23.5456C102.144 20.3738 100.669 17.9023 97.6869 17.9023C94.7046 17.9023 93.2299 20.3408 93.2299 23.5456Z" fill="#FFFFFF" />
                    <g id="logo-drops">
                        <use href="#target-g-1" transform="scale(1.0 1.0)" transform-origin="100 100" />
                        <path d="M127.403 8.95923L127.341 8.85213L121.974 0.189453L118.25 6.27351C119.234 6.36413 120.141 6.62776 120.964 7.0644L121.986 5.392L124.989 10.2362C125.183 10.6399 125.277 11.0559 125.277 11.5049C125.277 12.2958 125.038 12.9137 124.556 13.445C124.511 13.4945 124.474 13.5521 124.425 13.6016C124.124 13.894 123.766 14.1082 123.354 14.2483C122.962 14.3801 122.522 14.4501 122.023 14.4501C121.451 14.4501 120.948 14.3389 120.491 14.1329C120.178 13.9929 119.881 13.8158 119.617 13.5727C119.609 13.5645 119.601 13.5521 119.593 13.5439C118.995 12.9878 118.711 12.3411 118.711 11.5049C118.711 11.4596 118.728 11.4225 118.728 11.3772C118.373 11.1836 117.97 11.0847 117.475 11.0847C116.672 11.0847 116.244 11.2907 115.98 11.4967V11.5049C115.98 13.1279 116.586 14.5037 117.78 15.587C117.912 15.7065 118.056 15.793 118.192 15.9001C118.481 16.1225 118.781 16.312 119.098 16.4768C119.931 16.9052 120.853 17.1441 121.875 17.1647C121.924 17.1647 121.97 17.177 122.019 17.177C122.423 17.177 122.81 17.14 123.181 17.0823C124.416 16.8887 125.471 16.3861 126.319 15.5623C127.436 14.4831 128 13.1196 128 11.509C128 10.6193 127.798 9.76247 127.395 8.96747L127.403 8.95923Z" fill="#005FAA" />
                        <path d="M117.521 19.0553L114.518 14.2153C114.324 13.8116 114.23 13.3955 114.23 12.9466C114.23 12.1557 114.469 11.5378 114.95 11.0064C114.996 10.957 115.033 10.8993 115.082 10.8499C115.383 10.5574 115.741 10.3432 116.153 10.2032C116.545 10.0713 116.985 10.0013 117.484 10.0013C118.056 10.0013 118.559 10.1125 119.016 10.3185C119.329 10.4586 119.626 10.6357 119.889 10.8787C119.898 10.887 119.906 10.8993 119.914 10.9075C120.511 11.4636 120.796 12.1104 120.796 12.9466C120.796 12.9919 120.779 13.0289 120.779 13.0742C121.133 13.2678 121.537 13.3667 122.031 13.3667C122.835 13.3667 123.263 13.1607 123.527 12.9548V12.9466C123.527 11.3236 122.921 9.94777 121.727 8.86442C121.595 8.74497 121.451 8.65846 121.315 8.55137C121.026 8.32893 120.726 8.13945 120.408 7.97468C119.576 7.54628 118.654 7.30737 117.632 7.28677C117.583 7.28677 117.537 7.27441 117.488 7.27441C117.084 7.27441 116.697 7.31149 116.326 7.36916C115.091 7.56276 114.036 8.0653 113.187 8.88914C112.071 9.96837 111.507 11.3318 111.507 12.9424C111.507 13.8322 111.709 14.689 112.112 15.484L112.174 15.5911L117.541 24.2538L121.265 18.1738C120.281 18.0832 119.374 17.8196 118.551 17.3829L117.529 19.0553H117.521Z" fill="#009FE3" />
                    </g>
                </svg>
            </div>
            <div className="loading-txt">
                Loading App...
            </div>
        </div>
    </>)
}

