import { useContext, useState } from "react";
import { ModuleSelectModal } from "./ModuleSelectModal";
import { IconButton, useTheme } from "@mui/material";
import { ImplicoIcon } from "../ImplicoIcon";
import { ModulesVisibleInCoreContext } from "../providers/ModulesVisibleInCoreContext";

export function AppsButton() {
  const theme = useTheme();
  const [open, setOpen] = useState(false);
  const modulesVisibleInCore = useContext(ModulesVisibleInCoreContext);

  if (modulesVisibleInCore.length < 2) {
    return null;
  }

  return (
    <>
      <IconButton onClick={() => setOpen(true)} sx={{ color: theme.palette.primary.main }}>
        <ImplicoIcon id="grid" />
      </IconButton>

      <ModuleSelectModal
        open={open}
        onClose={() => setOpen(false)}
      />
    </>
  )
}
