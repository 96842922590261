import { useContext, useState } from 'react';
import { Avatar, Button, Divider, Grid, IconButton, Link, MenuItem, Popover, Typography, useTheme } from '@mui/material';
import { LogoutModal } from './LogoutModal';
import { TokensContext } from './providers/TokensContext';
import { AasTokenContext } from './providers/AasTokenContext';
import { ImplicoIcon } from './ImplicoIcon';
import * as colors from './colors';

export function ProfileMenu() {
  const theme = useTheme();
  const { tokens } = useContext(TokensContext);
  const { endpoint } = useContext(AasTokenContext);

  const [anchorEl, setAnchorEl] = useState<Element | null>(null);
  const [logoutOpen, setLogoutOpen] = useState(false);

  const handleClick = (target: Element) => {
    setAnchorEl(target);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);

  const urlSearchParams = new URLSearchParams();
  urlSearchParams.append("returnUrl", window.location.href);

  return (
    <>
      <IconButton onClick={(e) => handleClick(e.currentTarget)}>
        <Avatar sx={{ backgroundColor: colors.green }}>
          <ImplicoIcon id="user-smile"></ImplicoIcon>
        </Avatar>
      </IconButton>

      <Popover
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
      >
        <div style={{ width: '202px', padding: '1rem' }}>
          <Typography variant="overline" style={{ color: colors.gray }}>USER ACCOUNT</Typography>

          <Grid container>
            <Grid item sx={{ color: theme.palette.primary.dark }}><ImplicoIcon id="user-smile"></ImplicoIcon></Grid>
            <Grid item sx={{ fontWeight: 'bold', fontSize: 'smaller' }}>
              <div>{tokens.name}</div>
              <div>{tokens.accountName}</div>
            </Grid>
          </Grid>

          <ProfileMenuDivider></ProfileMenuDivider>

          <MenuItem>
            <Link href={endpoint + '/Account/ChangePassword?' + urlSearchParams}>Change Password</Link>
          </MenuItem>
          <MenuItem>
            <Link href={endpoint + '/authenticator/enable?' + urlSearchParams}>Enable MFA</Link>
          </MenuItem>
          <MenuItem>
            <Link href={endpoint + '/authenticator/disable?' + urlSearchParams}>Disable MFA</Link>
          </MenuItem>

          <ProfileMenuDivider></ProfileMenuDivider>

          <Divider></Divider>
          <Button variant="contained" fullWidth startIcon={<ImplicoIcon id="log-out"></ImplicoIcon>} onClick={() => setLogoutOpen(true)}>
            Log out
          </Button>
        </div>
      </Popover >

      <LogoutModal
        open={logoutOpen}
        onClose={() => setLogoutOpen(false)}
      />
    </>
  )
}

function ProfileMenuDivider() {
  const theme = useTheme();

  return <Divider sx={{
    borderColor: theme.palette.primary.main,
    marginTop: 1,
    marginBottom: 1,
    borderWidth: '1px',
  }} />;
}