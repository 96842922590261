import { ReactNode, createContext } from "react"
import { Module } from "../classic-only/classic-store";
import { useAas } from '../useAas';

export const ModulesVisibleInCoreContext = createContext<Module[]>(null!);

type Props = { children: ReactNode }

export function ModulesVisibleInCoreProvider({ children }: Props) {
  const state = useAas<Module[]>('/module/modules-visible-in-core');

  return <ModulesVisibleInCoreContext.Provider value={state!}>
    {
      state
        ? children
        : null
    }
  </ModulesVisibleInCoreContext.Provider>;
}
