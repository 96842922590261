import { ReactNode, createContext, useContext, useState } from "react"
import { UserInformationContext } from "./UserInformationContext";

export const ModuleEndpointContext = createContext<{
  moduleEndpoint: string,
  loadApplication: (applicationEndpoint: string, selectedModule: string) => void
}>(null!);

type Props = { children: ReactNode }

export function ModuleEndpointProvider({ children }: Props) {
  const [moduleEndpoint, setModuleEndpoint] = useState('');
  const { setSelectedModule } = useContext(UserInformationContext);

  const context = {
    moduleEndpoint,
    loadApplication(applicationEndpoint: string, selectedModule: string) {
      setSelectedModule(selectedModule);
      
      setModuleEndpoint(applicationEndpoint);
    }
  }

  return <ModuleEndpointContext.Provider value={context}>
    {children}
  </ModuleEndpointContext.Provider>;
}
