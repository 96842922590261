import { useContext, useEffect } from 'react';
import { UserInformationContext } from '../providers/UserInformationContext';
import { LanguageContext } from '../providers/LanguageContext';
import { ModulesVisibleInCoreContext } from '../providers/ModulesVisibleInCoreContext';

export function DocumentTitle() {
  const { selectedModule } = useContext(UserInformationContext);
  const modulesVisibleInCore = useContext(ModulesVisibleInCoreContext);
  const language = useContext(LanguageContext);

  const module = modulesVisibleInCore.find(x => x.code === selectedModule);

  const displayName = module?.displayName[language];
  const title = displayName === "Colas Portal" || displayName === "Evos Portal" ? "Customer Portal" : displayName;
  
  useEffect(() => {
    document.title = title ?? '';
  }, [title])


  return null;
}

