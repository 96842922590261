import { useCallback, useContext } from "react";
import { clearLocalStorageInHostedModule } from "./classic-only/iframe-synchronization";
import { AasTokenContext } from "./providers/AasTokenContext";
import { CoreModeContext } from "./providers/CoreModeContext";

export function useSwitchTerminal(): ((newTerminalGuid: string) => void) {
  const { endpoint } = useContext(AasTokenContext);
  const { authCallbackPath, moduleCode } = useContext(CoreModeContext);

  const result = useCallback((newTerminalGuid: string) => {
    clearLocalStorageInHostedModule();

    const returnUrl = new URL(window.location.href);
    returnUrl.pathname = authCallbackPath;

    const switchContextUrl = `${endpoint}/account/switch-security-context?module=${moduleCode}&terminalGuid=${newTerminalGuid}&ReturnUrl=${returnUrl}`;

    window.location.replace(switchContextUrl);
  }, [endpoint, authCallbackPath, moduleCode]);

  return result;
}