import { ImplicoIconRegularId } from '@implico/icon-library/dist/Implico-Icon-Regular';

type Props = {
  id: ImplicoIconRegularId;
  fontSize?: number;
};

export function ImplicoIcon({ id, fontSize = 24 }: Props) {
  return (
    <i className={`icon-regular--${id}`} style={{ fontSize: fontSize }} />
  );
}
