import { ReactNode } from "react"
import { CoreTheme } from './CoreTheme';
import { ModuleEndpointProvider } from './ModuleEndpointContext';
import { SettingsProvider } from './SettingsContext';
import { UserInformationProvider } from './UserInformationContext';
import { AvailableClientsAndTerminalsProvider } from './AvailableClientsAndTerminalsContext';
import { LanguageProvider } from "./LanguageContext";
import { TokensProvider } from "./TokensContext";
import { ModulesVisibleInCoreProvider } from "./ModulesVisibleInCoreContext";
import { AasTokenProvider } from "./AasTokenContext";
import { CoreModeProvider } from "./CoreModeContext";
import { CoreMode } from "../core-mode";
import { TokensStore } from "../tokens-store";

type Props = {
  mode: CoreMode,
  tokensStore: TokensStore,
  authCallbackPath: string;
  moduleCode: string;
  children: ReactNode;
};

export function AllProviders({ mode, tokensStore, authCallbackPath, moduleCode, children }: Props) {
  return <CoreTheme mode={mode}>
    <CoreModeProvider context={{ mode, tokensStore, authCallbackPath, moduleCode }}>
      <TokensProvider>
        <AasTokenProvider>
          <UserInformationProvider>
            <ModuleEndpointProvider>
              <AvailableClientsAndTerminalsProvider>
                <ModulesVisibleInCoreProvider>
                  <SettingsProvider>
                    <LanguageProvider>
                      {children}
                    </LanguageProvider>
                  </SettingsProvider>
                </ModulesVisibleInCoreProvider>
              </AvailableClientsAndTerminalsProvider>
            </ModuleEndpointProvider>
          </UserInformationProvider>
        </AasTokenProvider>
      </TokensProvider>
    </CoreModeProvider>
  </CoreTheme>;
}
