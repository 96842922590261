import { ReactNode, createContext, useContext } from "react";
import { TokensContext } from "./TokensContext";
import { ModuleToken } from "../tokens-store";

export const AasTokenContext = createContext<ModuleToken>(null!);

type Props = { children: ReactNode }

export function AasTokenProvider({ children }: Props) {
  const { tokens } = useContext(TokensContext);

  const module = tokens.modules.find(x => x.code === 'AAS');

  if (!module) {
    throw new Error('Really need AAS. ' + JSON.stringify(tokens.modules.map(x => x.code)));
  }

  return <AasTokenContext.Provider value={module!}>
    {
      tokens
        ? children
        : null
    }
  </AasTokenContext.Provider>;
}
