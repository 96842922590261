import { createContext, ReactNode } from 'react';
import { CoreMode } from '../core-mode';
import { TokensStore } from '../tokens-store';

type ContextType = {
  mode: CoreMode;
  tokensStore: TokensStore;
  authCallbackPath: string;
  moduleCode: string;
};

export const CoreModeContext = createContext<ContextType>(null!);

type Props = {
  context: ContextType,
  children: ReactNode
};

export function CoreModeProvider({ context, children }: Props) {

  return <CoreModeContext.Provider value={context}>
    {children}
  </CoreModeContext.Provider>;
}
