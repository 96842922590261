import { Tokens } from "../tokens-store";

const userModuleInfoKey = 'module-info';
const selectedModuleKey = 'selectedModule';

export const classicStore = {
    get selectedModule(): string | null {
        return localStorage.getItem(selectedModuleKey);
    },
    set selectedModule(value) {
        localStorage.setItem(selectedModuleKey, (value ?? "").trim());
    },
    
    userModuleInfo: {
        get() : Tokens | undefined {
            const raw = localStorage.getItem(userModuleInfoKey);

            return raw == undefined
                ? undefined
                : JSON.parse(raw).data;
        },
        set(obj: Tokens){
            localStorage.setItem(userModuleInfoKey, JSON.stringify({ data: obj }));
        },
        remove() {
            localStorage.removeItem(userModuleInfoKey);
        }
    },

    removeAllData() {
        localStorage.removeItem(userModuleInfoKey);
        localStorage.removeItem(selectedModuleKey);
    }
};

export type Settings = {
    Language: string;
    DefaultModule: string;
};

export type Module = {
    code: string;
    endpoint: string;    
    displayName: Record<string, string>;
};

export type Client = {
    clientGuid: string;
    name: string;
    terminals: Terminal[];
};

export type Terminal = {
    terminalGuid: string;
    name: string;
    address: string;
    city: string;
    postalCode: string;
    country: string;
    email: string;
    mobile: string;
    telephone: string;
};
