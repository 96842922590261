import { ThemeProvider, createTheme } from '@mui/material'
import { ReactNode } from 'react';
import * as colors from '../colors';
import { CoreMode } from '../core-mode';
import * as fonts from '../fonts';

const theme = createTheme({
  typography: {
    fontFamily: [
      'ibmplex--sans-regular',
      'Arial',
      'Roboto',
      'sans-serif',
    ].join(','),
    subtitle1: {
      fontFamily: fonts.plexBold
    }
  },
  palette: {
    primary: {
      main: '#009fe3',
      light: '#009fe3',
      dark: colors.blue,
      contrastText: 'white',
    }
  },
  components: {
    MuiFormControl: {
      styleOverrides: {
        root: {
          borderRadius: '4px',
          backgroundColor: 'white',
        }
      }
    },
    MuiOutlinedInput: {
      styleOverrides: {
        input: {
          color: colors.blue
        },
      }
    },
  }
});

type Props = {
  children: ReactNode;
  mode: CoreMode;
};

export function CoreTheme({ mode, children }: Props) {
  if (mode === 'classic') {
    try {
      theme.components!.MuiOutlinedInput!.styleOverrides!.notchedOutline = { borderWidth: '0px' };
    } catch { 
      //
    }
  }

  return (
    <ThemeProvider theme={theme}>
      {children}
    </ThemeProvider>
  )
}
