import { useContext } from "react";
import { ModuleEndpointContext } from "../providers/ModuleEndpointContext";
import { LoadingLogo } from "../LoadingLogo";

export function ModuleContainer() {

  const { moduleEndpoint } = useContext(ModuleEndpointContext)

  return moduleEndpoint
          ? <iframe className='flex-grow-1' src={moduleEndpoint} id="moduleIframe"></iframe>
          : <LoadingLogo></LoadingLogo>;
}
