import { ApplicationName } from './classic-only/ApplicationName'
import { AppsButton } from './classic-only/AppsButton'
import { ProfileMenu } from './ProfileMenu'
import { ResolveAppToShow } from './classic-only/ResolveAppToShow'
import { TerminalInfo } from './TerminalInfo'
import { UserLocations } from './UserLocations'
import { Version } from './Version'
import { DocumentTitle } from './classic-only/DocumentTitle'
import { ModuleContainer } from './classic-only/ModuleContainer'
import { AllProviders } from './providers/AllProviders'
import { CoreMode } from './core-mode'
import { TokensStore } from './tokens-store'
import * as colors from './colors';
import { Grid } from '@mui/material'

type Props = {
  mode: CoreMode;
  tokensStore: TokensStore;
  authCallbackPath: string;
  moduleCode: string;
};

export function App({ mode, tokensStore, authCallbackPath, moduleCode }: Props) {

  return (
    <AllProviders mode={mode} tokensStore={tokensStore} authCallbackPath={authCallbackPath} moduleCode={moduleCode}>

      {mode === 'embedded'
        ? <EmbeddedModeLayout />
        : <ClassicModeLayout />}

    </AllProviders>
  )
}

function ClassicModeLayout() {
  return (
    <>
      <DocumentTitle></DocumentTitle>

      <ResolveAppToShow></ResolveAppToShow>

      <div className='d-flex flex-column h-100'>

        <Grid container alignItems={'center'} sx={{
          backgroundColor: colors.lightGreyBlue,
        }}>
          <Grid item><AppsButton></AppsButton></Grid>
          <Grid item><Version></Version></Grid>
          <Grid item xs></Grid>
          <Grid item><ApplicationName></ApplicationName></Grid>
          <Grid item xs></Grid>
          <Grid item><UserLocations></UserLocations></Grid>
          <Grid item><TerminalInfo></TerminalInfo></Grid>
          <Grid item><ProfileMenu></ProfileMenu></Grid>
        </Grid>

        <ModuleContainer></ModuleContainer>

      </div>
    </>
  );
}

function EmbeddedModeLayout() {
  return (
    <Grid container alignItems={'center'}>
      <Grid item><UserLocations /></Grid>
      <Grid item><TerminalInfo /></Grid>
      <Grid item><ProfileMenu /></Grid>
    </Grid>
  );
}
