import { useContext } from 'react';
import { Module } from './classic-store';
import { LanguageContext } from '../providers/LanguageContext';
import { CoreModeContext } from '../providers/CoreModeContext';
import { AasTokenContext } from '../providers/AasTokenContext';
import { Box, useTheme } from '@mui/material';
import { ImplicoIcon } from '../ImplicoIcon';

export type ApplicationMenuItemProps = {
  application: Module;
  onClick: () => void;
};

export function ApplicationMenuItem({ application, onClick }: ApplicationMenuItemProps) {
  const theme = useTheme();
  const language = useContext(LanguageContext);
  const { mode } = useContext(CoreModeContext);
  const { endpoint } = useContext(AasTokenContext);

  const url = mode === 'embedded'
    ? application.endpoint
    : '#';

  if (mode === 'embedded') {
    onclick = () => { };
  }

  return (<a href={url} style={{ textDecoration: 'none', height: '100%', display: 'inline-block' }} onClick={onClick}>
    <div className="card" style={{ height: '100%', }}>
      <div className="card-body d-flex justify-content-center align-items-center">
        <img src={endpoint + '/module/image/' + application.code} className="p-1" style={{ width: '75%' }} />
      </div>
      <div className="card-footer d-flex justify-content-between" style={{ backgroundColor: theme.palette.background.default }}>
        <div>{application.displayName[language]}</div>

        <Box sx={{ color: theme.palette.primary.dark }}>
          <ImplicoIcon id="arrow-right" />
        </Box>

      </div>
    </div>
  </a>
  )
}
