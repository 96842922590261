import React from 'react'
import ReactDOM from 'react-dom/client'

import "bootstrap/dist/css/bootstrap.min.css";

import "./assets/css/styling.css";
import "./assets/css/core.css";

import { App } from './App.tsx'

import '@implico/icon-library';
import { CoreMode } from './core-mode.ts';
import { TokensStore } from './tokens-store.ts';

export function displayCore(element: HTMLElement, mode: CoreMode, tokensStore: TokensStore, authCallbackPath: string, moduleCode: string) {
  ReactDOM.createRoot(element).render(
    <React.StrictMode>
      <App mode={mode} tokensStore={tokensStore} authCallbackPath={authCallbackPath} moduleCode={moduleCode} />
    </React.StrictMode>,
  );
}
