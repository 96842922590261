import { classicStore } from "./classic-store";

export function subscribeToIframeMessages() {
    window.addEventListener('message', function (e) {

      if(typeof e.data !== 'string') {
        return;
      }

        const data = JSON.parse(e.data);
        /* message is of the form
          const message = JSON.stringify({
	    	module: config.moduleCode,
			location: location.pathname,
			query: location.search
		  });

          note that location is _not_ the location but the pathname
        */

        const urlSearchParams = new URLSearchParams();
        urlSearchParams.set('module', classicStore.selectedModule ?? '');
        
        const path = data.location;
        urlSearchParams.set('path', path ?? '');

        const query = data.query;
        urlSearchParams.set('query', query ?? '');
        
        const newRelativePathQuery = window.location.pathname + '?' + urlSearchParams;
        history.pushState({}, '', newRelativePathQuery);
    });
}

export function clearLocalStorageInHostedModule() {
  const iframe = document.getElementById("moduleIframe") as HTMLIFrameElement;  
  iframe?.contentWindow?.postMessage("please-clear-local-storage", '*'); // it might be that nothing is there
}