import { ReactNode, createContext } from "react"
import { Client } from "../classic-only/classic-store";
import { useAas } from '../useAas';

export const AvailableClientsAndTerminalsContext = createContext<Client[]>(null!);

type Props = { children: ReactNode }

export function AvailableClientsAndTerminalsProvider({ children }: Props) {
  const state = useAas<Client[]>('/account/available-clients-and-terminals');

  return <AvailableClientsAndTerminalsContext.Provider value={state!}>
    {
      state != null
        ? children
        : null
    }
  </AvailableClientsAndTerminalsContext.Provider>;
}
