import { Autocomplete, TextField } from "@mui/material";
import { Terminal } from "./classic-only/classic-store";
import { useContext } from "react";
import { useSwitchTerminal } from "./useSwitchTerminal";
import { AvailableClientsAndTerminalsContext } from "./providers/AvailableClientsAndTerminalsContext";
import { TokensContext } from "./providers/TokensContext";

type Item = Terminal & {
  clientName: string;
};

export function UserLocations() {
  const { tokens } = useContext(TokensContext);
  const availableClientsAndTerminals = useContext(AvailableClientsAndTerminalsContext);
  const switchTerminal = useSwitchTerminal();

  const terminals: Item[] = availableClientsAndTerminals
    .flatMap(x => x.terminals.map(t => ({ ...t, clientName: x.name })))

  if (terminals.length === 0) {
    return <></>;
  }

  const currentTerminal = terminals.find(t => t.terminalGuid === tokens.terminalGuid);

  return (
    <>
      <Autocomplete
        size="small"
        sx={{ width: 200 }}
        disableClearable={true}
        renderInput={(params) => <TextField {...params} />}
        options={terminals}
        disabled={terminals.length === 1}
        groupBy={t => t.clientName}
        getOptionLabel={t => t.name}
        value={currentTerminal}
        onChange={(_: React.SyntheticEvent<Element, Event>, newValue: Item | null) => {
          if (newValue) {
            switchTerminal(newValue.terminalGuid);
          }
        }}
      />
    </>
  );
}
