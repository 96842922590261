import React, { useContext } from 'react';
import { Avatar, Grid, IconButton, Popover, Typography, useTheme } from '@mui/material';
import { Terminal } from './classic-only/classic-store';
import { TokensContext } from './providers/TokensContext';
import { AvailableClientsAndTerminalsContext } from './providers/AvailableClientsAndTerminalsContext';
import { ImplicoIcon } from './ImplicoIcon';
import * as fonts from './fonts';

export function TerminalInfo() {
  const theme = useTheme();
  const [anchorEl, setAnchorEl] = React.useState<Element | null>(null);

  const { tokens } = useContext(TokensContext);
  const availableClientsAndTerminals = useContext(AvailableClientsAndTerminalsContext);

  const terminal = availableClientsAndTerminals
    .flatMap(x => x.terminals)
    .find(x => x.terminalGuid === tokens.terminalGuid) ?? {} as Terminal;

  const handleClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);

  return (
    <>
      <IconButton onClick={e => setAnchorEl(e.currentTarget)} sx={{ paddingRight: 0 }}>
        <Avatar sx={{ backgroundColor: theme.palette.primary.dark }}>
          <ImplicoIcon id="information"></ImplicoIcon>
        </Avatar>
      </IconButton>

      <Popover
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'center',
        }}
      >
        <div style={{ width: '202px', padding: '1rem' }}>
          <IconButton aria-label="Close" onClick={handleClose} sx={{ position: 'absolute', right: 0, top: 0, color: theme.palette.primary.main }}>
            <ImplicoIcon id="clear" />
          </IconButton>

          <Grid container spacing={1}>
            <Grid item>
              <Typography variant="subtitle1">{terminal.name}</Typography>
            </Grid>

            <Grid item>
              {terminal.address}<br />
              {terminal.postalCode} {terminal.city}<br />
              {terminal.country}
            </Grid>

            <Grid item>
              <strong style={{ fontFamily: fonts.plexBold }}>T</strong> {terminal.telephone}<br />
              <strong style={{ fontFamily: fonts.plexBold }}>M</strong> {terminal.mobile}
            </Grid>
          </Grid>
        </div>
      </Popover>
    </>
  )
}
