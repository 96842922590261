import { Typography, useTheme } from '@mui/material';
import { useContext } from 'react';
import { UserInformationContext } from '../providers/UserInformationContext';
import { LanguageContext } from '../providers/LanguageContext';
import { ModulesVisibleInCoreContext } from '../providers/ModulesVisibleInCoreContext';

export function ApplicationName() {
  const theme = useTheme();
  const { selectedModule } = useContext(UserInformationContext);
  const modulesVisibleInCore = useContext(ModulesVisibleInCoreContext);
  const language = useContext(LanguageContext);

  const module = modulesVisibleInCore.find(x => x.code === selectedModule);

  const title = module?.displayName[language];

  return (
    <Typography variant='h5' sx={{ color: theme.palette.primary.dark }}>{title}</Typography>
  )
}
