import { ReactNode, createContext, useState } from "react"
import {  classicStore } from "../classic-only/classic-store";

export type UserInformationContextType = {
  selectedModule: string | null,
  setSelectedModule: (newValue: string) => void
};

export const UserInformationContext = createContext<UserInformationContextType>(null!);

type Props = { children: ReactNode }

export function UserInformationProvider({ children }: Props) {
  const [state, setState] = useState({
    selectedModule: classicStore.selectedModule
  })

  const context: UserInformationContextType = {
    selectedModule: state.selectedModule,
  
    setSelectedModule: function (newValue: string) {
      classicStore.selectedModule = newValue;
      setState({
        ...context,        
        selectedModule: newValue,
      });
    }
  }
  
  return <UserInformationContext.Provider value={context}>
    {children}
  </UserInformationContext.Provider>;
}
