import { ReactNode, createContext, useContext } from "react"
import { SettingsContext } from "./SettingsContext";

export const LanguageContext = createContext<string>(null!);

type Props = { children: ReactNode }

export function LanguageProvider({ children }: Props) {
  const settings = useContext(SettingsContext);

  const language = settings.Language ?? navigator.language;

  return <LanguageContext.Provider value={language}>
    {children}
  </LanguageContext.Provider>;
}
