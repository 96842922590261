import { ReactNode, createContext, useContext, useEffect, useState } from "react";
import config from '../../config.json';
import { classicStore } from "../classic-only/classic-store";
import { redirectToLogin } from '../redirect-to-login';
import { Tokens } from "../tokens-store";
import { CoreModeContext } from "./CoreModeContext";

type ContextType = {
  tokens: Tokens;
};

export const TokensContext = createContext<ContextType>(null!);

type Props = { children: ReactNode }

export function TokensProvider({ children }: Props) {
  const tokens = useTokens();

  const context: ContextType = {
    tokens: tokens!,
  };

  return <TokensContext.Provider value={context}>
    {
      tokens
        ? children
        : null
    }
  </TokensContext.Provider>;
}

export async function exchangeIsidForUserInfo(isid: string): Promise<Tokens> {
  const response = await fetch(`${config.authApi.baseUrl}/api/externallogin/verify`, {
    method: 'POST',
    body: JSON.stringify(isid),
    headers: {
      'Content-Type': 'application/json',
    },
  });

  const data = await response.json();

  return data;
}

function useTokens(): Tokens | undefined {
  const { mode, tokensStore } = useContext(CoreModeContext);
  const [tokens, setTokens] = useState(tokensStore.getTokens());

  useEffect(() => {

    // attach our callback, it will be invoked when tokens change
    tokensStore.tokensChanged = () => {
      setTokens(tokensStore.getTokens());
    };

    return () => {
      tokensStore.tokensChanged = () => { };
    };
  }, [tokensStore]); 

  useEffect(() => {
    (async function () {
      if (mode === 'embedded') {
        // do nothing - host should realize that there are no tokens and initiate auth sequence
      } else {
        const queryStringParameters = new URLSearchParams(window.location.search);
        const isid = queryStringParameters.get("isid");
        if (isid) {
          const tokens = await exchangeIsidForUserInfo(isid);

          classicStore.userModuleInfo.set(tokens);
          setTokens(tokens);

          // strip isid
          window.location.search = '';

          console.log('isid stripped');

          return;
        }

        const tokens = tokensStore.getTokens();
        if (!tokens) {
          console.log('No tokens - going to AAS.');

          redirectToLogin();

          return; // not yet logged in
        }
      }
    })();
  }, [mode, tokensStore]);

  return tokens;
}