import { ReactNode, createContext } from "react"
import { Settings } from "../classic-only/classic-store";
import { useAas } from "../useAas";

export const SettingsContext = createContext<Settings>(null!);

type Props = { children: ReactNode }

export function SettingsProvider({ children }: Props) {
  const settings = useAas<Settings>('/account/settings');

  return <SettingsContext.Provider value={settings!}>
    {
      settings
        ? children
        : null
    }
  </SettingsContext.Provider>;
}
